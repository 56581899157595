/**
 * Created by mac on 7/22/17.
 */

Goals.prototype.attack = function () {
    var bestType = undefined;
    for (var type in this.elements) {
        if (!this.elements[type].done) {
            if (this.elements[type].amountAttacks === undefined) {
                this.elements[type].amountAttacks = 0;
            }
            if (bestType === undefined || this.elements[type].amountAttacks < this.elements[bestType].amountAttacks) {
                bestType = type;
            }
        }
    }

    if (bestType !== undefined) {
        this.elements[bestType].amountAttacks++;
        this.elements[bestType].trigger("attack");
        return true;
    }

    return false;
};
