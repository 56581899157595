/**
 * Created by anatoly on 06.11.2024
 */
if (typeof PrizeCalendarTileView !== "undefined") {
    PrizeCalendarTileView.prototype.collect = function (callback) {
        var styles = cleverapps.styles.PrizeCalendarTileView;

        var rewardComponent = new RewardsListComponent(this.rewardsList, {
            fitToBox: styles.rewards.fitToBox,
            textMargin: -10,
            font: cleverapps.styles.FONTS.PRIZE_CALENDAR_REWARD
        });

        this.addChild(rewardComponent);
        rewardComponent.setPositionRound(styles.rewards);

        var scene = cleverapps.scenes.getRunningScene();
        var bg = this.innerBg;

        bg.replaceParentSamePlace(scene);
        bg.setLocalZOrder(scene.movingNode.getLocalZOrder() + 1);

        if (this.isLastTile()) {
            this.prizeCalendar.trigger("chestAnimation", this.rewardsList);
        } else {
            rewardComponent.receiveRewardsAnimation({
                fadeDelay: 0,
                iconAction: function (icon) {
                    icon.replaceParentSamePlace(cleverapps.scenes.getMovingNode(icon));
                    return new cc.Sequence(
                        cc.JumpAnimation(0.5, icon, cc.p(0, 200), 200),
                        new cc.CallFunc(function () {
                            bg.replaceParentSamePlace(this.content);
                            bg.setLocalZOrder(0);
                        }.bind(this))
                    );
                }.bind(this),
                callback: callback,
                delay: 500
            });
        }
    };

    PrizeCalendarTileView.prototype.showUpReward = function () {
        var scene = cleverapps.scenes.getRunningScene();

        var bg = this.innerBg;

        bg.replaceParentSamePlace(scene);
        bg.setLocalZOrder(scene.movingNode.getLocalZOrder() + 1);

        bg.setAnimation(0, "w_box_drop_prize");

        var rewardIcon = this.rewardsList.rewards[0].getIcon();

        rewardIcon.setPosition(this.width / 2, this.height / 2 + 200);
        this.addChild(rewardIcon);

        rewardIcon.replaceParentSamePlace(scene);
        rewardIcon.setLocalZOrder(bg.getLocalZOrder() - 1);

        rewardIcon.setVisible(false);
        rewardIcon.runAction(new cc.Sequence(
            AnimationsLibrary.showUp(rewardIcon, { flash: true }),
            new cc.MoveBy(0.3, 0, -225),
            new cc.RemoveSelf(),
            new cc.CallFunc(function () {
                bg.replaceParentSamePlace(this.content);
                bg.setLocalZOrder(0);
            }.bind(this))
        ));
    };
}
