/**
 * Created by iamso on 08.11.18.
 */

cleverapps.styles.IslandEnemies = {
    "0": [{
        enemy: bundles.enemy2.jsons.enemy2,
        x: -140,
        y: -130
    }, {
        enemy: bundles.enemy1.jsons.enemy1,
        x: 180,
        y: -130,
        flipped: true
    }],

    "1": [{
        enemy: bundles.enemy3.jsons.enemy3,
        x: -30,
        y: -123,
        scale: 0.5
    }, {
        enemy: bundles.enemy4.jsons.enemy4,
        x: 150,
        y: -85,
        flipped: true
    }],

    "2": [{
        enemy: bundles.enemy5.jsons.enemy5,
        x: 10,
        y: -120,
        scale: 0.5
    }],

    "3": [{
        enemy: bundles.enemy0.jsons.enemy0,
        x: 100,
        y: -120,
        flipped: true
    }, {
        enemy: bundles.enemy6.jsons.enemy6,
        x: -40,
        y: -140,
        flipped: true
    }],

    "4": [{
        enemy: bundles.enemy1.jsons.enemy1,
        x: -100,
        y: -140
    }, {
        enemy: bundles.enemy3.jsons.enemy3,
        x: 110,
        y: -190,
        flipped: true
    }],

    "5": [{
        enemy: bundles.enemy8.jsons.enemy8,
        x: 145,
        y: -145,
        flipped: true
    }, {
        enemy: bundles.enemy9.jsons.enemy9,
        x: -15,
        y: -175,
        flipped: false
    }],

    "6": [{
        enemy: bundles.enemy2.jsons.enemy2,
        x: 110,
        y: -45,
        flipped: true
    }, {
        enemy: bundles.enemy7.jsons.enemy7,
        x: 30,
        y: -155,
        flipped: false
    }],

    "7": [{
        enemy: bundles.enemy0.jsons.enemy0,
        x: -40,
        y: 10,
        flipped: true
    }, {
        enemy: bundles.enemy8.jsons.enemy8,
        x: 70,
        y: -40,
        flipped: false
    }],

    "8": [{
        enemy: bundles.enemy4.jsons.enemy4,
        x: 125,
        y: -110,
        flipped: true
    }, {
        enemy: bundles.enemy5.jsons.enemy5,
        x: -95,
        y: -130,
        flipped: false
    }],

    "9": [{
        enemy: bundles.enemy3.jsons.enemy3,
        x: 130,
        y: -35,
        flipped: true
    }, {
        enemy: bundles.enemy9.jsons.enemy9,
        x: -90,
        y: -95,
        flipped: false
    }],

    "10": [{
        enemy: bundles.enemy2.jsons.enemy2,
        x: 90,
        y: -40,
        flipped: true
    }, {
        enemy: bundles.enemy2.jsons.enemy2,
        x: -130,
        y: -100,
        flipped: false
    }],

    "11": [{
        enemy: bundles.enemy9.jsons.enemy9,
        x: 130,
        y: -90
    }],

    "12": [{
        enemy: bundles.enemy11.jsons.enemy11,
        x: -175,
        y: -140
    }, {
        enemy: bundles.enemy8.jsons.enemy8,
        x: 125,
        y: -160,
        flipped: true
    }],

    "13": [{
        enemy: bundles.enemy15.jsons.enemy15,
        x: 30,
        y: -80
    }, {
        enemy: bundles.enemy5.jsons.enemy5,
        x: -90,
        y: -130,
        flipped: true
    }],

    "14": [{
        enemy: bundles.enemy0.jsons.enemy0,
        x: -50,
        y: 40
    }, {
        enemy: bundles.enemy6.jsons.enemy6,
        x: 190,
        y: -70,
        flipped: true
    }, {
        enemy: bundles.enemy3.jsons.enemy3,
        x: 50,
        y: -180
    }],

    "15": [{
        enemy: bundles.enemy11.jsons.enemy11,
        x: 60,
        y: -5
    }, {
        enemy: bundles.enemy8.jsons.enemy8,
        x: 210,
        y: -45
    }, {
        enemy: bundles.enemy15.jsons.enemy15,
        x: 10,
        y: -145
    }],

    "16": [{
        enemy: bundles.enemy5.jsons.enemy5,
        x: 40,
        y: -80
    }, {
        enemy: bundles.enemy9.jsons.enemy9,
        x: -160,
        y: -130
    }],

    "17": [{
        enemy: bundles.enemy4.jsons.enemy4,
        x: 115,
        y: -25,
        flipped: true
    }, {
        enemy: bundles.enemy2.jsons.enemy2,
        x: -165,
        y: -75
    }],

    "18": [{
        enemy: bundles.enemy1.jsons.enemy1,
        x: -120,
        y: -115
    }],

    "19": [{
        enemy: bundles.enemy12.jsons.enemy12,
        x: -120,
        y: -95
    }, {
        enemy: bundles.enemy7.jsons.enemy7,
        x: 140,
        y: -105
    }],

    "20": [{
        enemy: bundles.enemy11.jsons.enemy11,
        x: 15,
        y: -185,
        flipped: true
    }],

    "21": [{
        enemy: bundles.enemy0.jsons.enemy0,
        x: -60,
        y: -95
    }, {
        enemy: bundles.enemy7.jsons.enemy7,
        x: 120,
        y: -15,
        flipped: true
    }],

    "22": [{
        enemy: bundles.enemy15.jsons.enemy15,
        x: -50,
        y: -125
    }, {
        enemy: bundles.enemy12.jsons.enemy12,
        x: 130,
        y: -145,
        flipped: true
    }],

    "23": [{
        enemy: bundles.enemy3.jsons.enemy3,
        x: -110,
        y: 10
    }, {
        enemy: bundles.enemy8.jsons.enemy8,
        x: 250,
        y: -170
    }],

    "24": [{
        enemy: bundles.enemy15.jsons.enemy15,
        x: -165,
        y: -100
    }, {
        enemy: bundles.enemy1.jsons.enemy1,
        x: 95,
        y: -160,
        flipped: true
    }],

    "25": [{
        enemy: bundles.enemy13.jsons.enemy13,
        x: -120,
        y: -95,
        flipped: true
    }],

    "26": [{
        enemy: bundles.enemy8.jsons.enemy8,
        x: -155,
        y: -95
    }, {
        enemy: bundles.enemy2.jsons.enemy2,
        x: 85,
        y: -95,
        flipped: true
    }],

    "27": [{
        enemy: bundles.enemy14.jsons.enemy14,
        x: -95,
        y: -65
    }, {
        enemy: bundles.enemy12.jsons.enemy12,
        x: 55,
        y: -105,
        flipped: true
    }],

    "28": [{
        enemy: bundles.enemy13.jsons.enemy13,
        x: -130,
        y: -90
    }, {
        enemy: bundles.enemy11.jsons.enemy11,
        x: 170,
        y: -110,
        flipped: true
    }],

    "29": [{
        enemy: bundles.enemy15.jsons.enemy15,
        x: -10,
        y: -35,
        flipped: true
    }],

    "30": [{
        enemy: bundles.enemy14.jsons.enemy14,
        x: -120,
        y: -95
    }, {
        enemy: bundles.enemy9.jsons.enemy9,
        x: 60,
        y: -135,
        flipped: true
    }],

    "31": [{
        enemy: bundles.enemy13.jsons.enemy13,
        x: -70,
        y: -55
    }],

    "32": [{
        enemy: bundles.enemy6.jsons.enemy6,
        x: 125,
        y: -115
    }, {
        enemy: bundles.enemy12.jsons.enemy12,
        x: -145,
        y: -125,
        flipped: true
    }],

    "33": [{
        enemy: bundles.enemy14.jsons.enemy14,
        x: 130,
        y: -65,
        flipped: true
    }, {
        enemy: bundles.enemy13.jsons.enemy13,
        x: -70,
        y: -85
    }],

    "34": [{
        enemy: bundles.enemy0.jsons.enemy0,
        x: 85,
        y: -30
    }, {
        enemy: bundles.enemy14.jsons.enemy14,
        x: 45,
        y: -110
    }, {
        enemy: bundles.enemy8.jsons.enemy8,
        x: -35,
        y: -160
    }],

    "35": [{
        enemy: bundles.enemy16.jsons.enemy16,
        x: 110,
        y: -50,
        flipped: true
    }, {
        enemy: bundles.enemy9.jsons.enemy9,
        x: -50,
        y: -70
    }],

    "36": [{
        enemy: bundles.enemy15.jsons.enemy15,
        x: 100,
        y: -60,
        flipped: true
    }, {
        enemy: bundles.enemy16.jsons.enemy16,
        x: -120,
        y: -90
    }],

    "37": [{
        enemy: bundles.enemy10.jsons.enemy10,
        x: -50,
        y: -5
    }, {
        enemy: bundles.enemy13.jsons.enemy13,
        x: 150,
        y: -75
    }],

    "38": [{
        enemy: bundles.enemy10.jsons.enemy10,
        x: -75,
        y: -125
    }, {
        enemy: bundles.enemy11.jsons.enemy11,
        x: 75,
        y: -175,
        flipped: true
    }],

    "39": [{
        enemy: bundles.enemy0.jsons.enemy0,
        x: 100,
        y: -35,
        flipped: true
    }, {
        enemy: bundles.enemy19.jsons.enemy19,
        x: -70,
        y: -35
    }],

    "40": [{
        enemy: bundles.enemy15.jsons.enemy15,
        x: 195,
        y: -110,
        flipped: true
    }, {
        enemy: bundles.enemy14.jsons.enemy14,
        x: -115,
        y: -130
    }],

    "41": [{
        enemy: bundles.enemy18.jsons.enemy18,
        x: 95,
        y: -55,
        flipped: true
    }, {
        enemy: bundles.enemy19.jsons.enemy19,
        x: -65,
        y: -75
    }],

    "42": [{
        enemy: bundles.enemy16.jsons.enemy16,
        x: -110,
        y: -95,
        flipped: true
    }],

    "43": [{
        enemy: bundles.enemy18.jsons.enemy18,
        x: -115,
        y: 10
    }, {
        enemy: bundles.enemy19.jsons.enemy19,
        x: 105,
        y: -90
    }],

    "44": [{
        enemy: bundles.enemy9.jsons.enemy9,
        x: -30,
        y: -135
    }, {
        enemy: bundles.enemy16.jsons.enemy16,
        x: 170,
        y: -35,
        flipped: true
    }],

    "45": [{
        enemy: bundles.enemy10.jsons.enemy10,
        x: -90,
        y: -115
    }, {
        enemy: bundles.enemy15.jsons.enemy15,
        x: 130,
        y: -15,
        flipped: true
    }],

    "46": [{
        enemy: bundles.enemy16.jsons.enemy16,
        x: 80,
        y: -130,
        flipped: true
    }, {
        enemy: bundles.enemy15.jsons.enemy15,
        x: -120,
        y: -50
    }],

    "47": [{
        enemy: bundles.enemy17.jsons.enemy17,
        x: 75,
        y: -35,
        flipped: true
    }, {
        enemy: bundles.enemy18.jsons.enemy18,
        x: -125,
        y: -5
    }],

    "48": [{
        enemy: bundles.enemy18.jsons.enemy18,
        x: -145,
        y: -55,
        flipped: true
    }, {
        enemy: bundles.enemy19.jsons.enemy19,
        x: 95,
        y: -45
    }, {
        enemy: bundles.enemy17.jsons.enemy17,
        x: -25,
        y: -85
    }],

    "49": [{
        enemy: bundles.enemy14.jsons.enemy14,
        x: -105,
        y: -110,
        flipped: true
    }, {
        enemy: bundles.enemy15.jsons.enemy15,
        x: 115,
        y: -90
    }],

    "50": [{
        enemy: bundles.enemy18.jsons.enemy18,
        x: 95,
        y: -200,
        flipped: true
    }, {
        enemy: bundles.enemy9.jsons.enemy9,
        x: -85,
        y: -150
    }],

    "51": [{
        enemy: bundles.enemy12.jsons.enemy12,
        x: -160,
        y: -85,
        flipped: true
    }],

    "52": [{
        enemy: bundles.enemy5.jsons.enemy5,
        x: 60,
        y: -85,
        flipped: true
    }, {
        enemy: bundles.enemy1.jsons.enemy1,
        x: -100,
        y: -165
    }],

    "53": [{
        enemy: bundles.enemy8.jsons.enemy8,
        x: -75,
        y: -85
    }],

    "54": [{
        enemy: bundles.enemy3.jsons.enemy3,
        x: -35,
        y: -85,
        flipped: true
    }, {
        enemy: bundles.enemy2.jsons.enemy2,
        x: 185,
        y: 15,
        flipped: true
    }],

    "55": [{
        enemy: bundles.enemy10.jsons.enemy10,
        x: -90,
        y: -110
    }, {
        enemy: bundles.enemy15.jsons.enemy15,
        x: 150,
        y: -50,
        flipped: true
    }],

    "56": [{
        enemy: bundles.enemy16.jsons.enemy16,
        x: 45,
        y: -95,
        flipped: true
    }, {
        enemy: bundles.enemy15.jsons.enemy15,
        x: -155,
        y: -35
    }],

    "57": [{
        enemy: bundles.enemy17.jsons.enemy17,
        x: 70,
        y: -100,
        flipped: true
    }, {
        enemy: bundles.enemy18.jsons.enemy18,
        x: -130,
        y: -40
    }],

    "58": [{
        enemy: bundles.enemy13.jsons.enemy13,
        x: -145,
        y: -65
    }],

    "59": [{
        enemy: bundles.enemy14.jsons.enemy14,
        x: -90,
        y: -65,
        flipped: true
    }, {
        enemy: bundles.enemy15.jsons.enemy15,
        x: 130,
        y: -85
    }],

    "60": [{
        enemy: bundles.enemy18.jsons.enemy18,
        x: 110,
        y: -155,
        flipped: true
    }, {
        enemy: bundles.enemy9.jsons.enemy9,
        x: -70,
        y: -135
    }],

    "61": [{
        enemy: bundles.enemy5.jsons.enemy5,
        x: 150,
        y: -65,
        flipped: true
    }, {
        enemy: bundles.enemy1.jsons.enemy1,
        x: -40,
        y: -155
    }],

    "62": [{
        enemy: bundles.enemy8.jsons.enemy8,
        x: 45,
        y: -110
    }, {
        enemy: bundles.enemy3.jsons.enemy3,
        x: -115,
        y: -80
    }],

    "63": [{
        enemy: bundles.enemy10.jsons.enemy10,
        x: 30,
        y: -50,
        flipped: true
    }, {
        enemy: bundles.enemy2.jsons.enemy2,
        x: -100,
        y: -120
    }],

    "64": [{
        enemy: bundles.enemy13.jsons.enemy13,
        x: 170,
        y: -30,
        flipped: true
    }, {
        enemy: bundles.enemy7.jsons.enemy7,
        x: -90,
        y: -130
    }],

    "65": [{
        enemy: bundles.enemy17.jsons.enemy17,
        x: 115,
        y: -140,
        flipped: true
    }, {
        enemy: bundles.enemy9.jsons.enemy9,
        x: -65,
        y: -200
    }],

    "66": [{
        enemy: bundles.enemy16.jsons.enemy16,
        x: -180,
        y: -70,
        flipped: true
    }, {
        enemy: bundles.enemy6.jsons.enemy6,
        x: 140,
        y: 20
    }],

    "67": [{
        enemy: bundles.enemy15.jsons.enemy15,
        x: 195,
        y: -155,
        flipped: true
    }, {
        enemy: bundles.enemy5.jsons.enemy5,
        x: -25,
        y: -170
    }],

    "68": [{
        enemy: bundles.enemy10.jsons.enemy10,
        x: 125,
        y: -180,
        flipped: true
    }, {
        enemy: bundles.enemy7.jsons.enemy7,
        x: -85,
        y: -75,
        flipped: true
    }],

    "69": [{
        enemy: bundles.enemy8.jsons.enemy8,
        x: -65,
        y: -185,
        flipped: true
    }, {
        enemy: bundles.enemy12.jsons.enemy12,
        x: 15,
        y: 45
    }],

    "70": [{
        enemy: bundles.enemy16.jsons.enemy16,
        x: 210,
        y: -180,
        flipped: true
    }, {
        enemy: bundles.enemy15.jsons.enemy15,
        x: -90,
        y: -70
    }],

    "71": [{
        enemy: bundles.enemy16.jsons.enemy16,
        x: -40,
        y: 20,
        flipped: true
    }, {
        enemy: bundles.enemy15.jsons.enemy15,
        x: -170,
        y: -190
    }],

    "72": [{
        enemy: bundles.enemy5.jsons.enemy5,
        x: 130,
        y: -135,
        flipped: true
    }, {
        enemy: bundles.enemy9.jsons.enemy9,
        x: -80,
        y: -55
    }],

    "73": [{
        enemy: bundles.enemy2.jsons.enemy2,
        x: 185,
        y: -50,
        flipped: true
    }, {
        enemy: bundles.enemy12.jsons.enemy12,
        x: -115,
        y: -90
    }],

    "74": [{
        enemy: bundles.enemy3.jsons.enemy3,
        x: -120,
        y: -60
    }, {
        enemy: bundles.enemy10.jsons.enemy10,
        x: 60,
        y: 0,
        flipped: true
    }],

    "75": [{
        enemy: bundles.enemy1.jsons.enemy1,
        x: 180,
        y: -115,
        flipped: true
    }, {
        enemy: bundles.enemy14.jsons.enemy14,
        x: -120,
        y: -15
    }],

    "76": [{
        enemy: bundles.enemy13.jsons.enemy13,
        x: 105,
        y: -125
    }],

    "77": [{
        enemy: bundles.enemy6.jsons.enemy6,
        x: 195,
        y: -160
    }, {
        enemy: bundles.enemy12.jsons.enemy12,
        x: 55,
        y: -110,
        flipped: true
    }],

    "78": [{
        enemy: bundles.enemy14.jsons.enemy14,
        x: 140,
        y: -105,
        flipped: true
    }, {
        enemy: bundles.enemy13.jsons.enemy13,
        x: -40,
        y: 15
    }],

    "79": [{
        enemy: bundles.enemy0.jsons.enemy0,
        x: 120,
        y: -75
    }],

    "80": [{
        enemy: bundles.enemy16.jsons.enemy16,
        x: 150,
        y: -125,
        flipped: true
    }, {
        enemy: bundles.enemy9.jsons.enemy9,
        x: -70,
        y: -35
    }],

    "81": [{
        enemy: bundles.enemy18.jsons.enemy18,
        x: 105,
        y: -215,
        flipped: true
    }, {
        enemy: bundles.enemy19.jsons.enemy19,
        x: -55,
        y: -195
    }],

    "82": [{
        enemy: bundles.enemy16.jsons.enemy16,
        x: 125,
        y: -165,
        flipped: true
    }],

    "83": [{
        enemy: bundles.enemy18.jsons.enemy18,
        x: -105,
        y: -85
    }, {
        enemy: bundles.enemy19.jsons.enemy19,
        x: 75,
        y: -145
    }],

    "84": [{
        enemy: bundles.enemy9.jsons.enemy9,
        x: 10,
        y: 0
    }],

    "85": [{
        enemy: bundles.enemy10.jsons.enemy10,
        x: -20,
        y: -105
    }, {
        enemy: bundles.enemy15.jsons.enemy15,
        x: 180,
        y: -125,
        flipped: true
    }],

    "86": [{
        enemy: bundles.enemy16.jsons.enemy16,
        x: 200,
        y: -150,
        flipped: true
    }, {
        enemy: bundles.enemy15.jsons.enemy15,
        x: -100,
        y: -140
    }],

    "87": [{
        enemy: bundles.enemy17.jsons.enemy17,
        x: -245,
        y: -80,
        flipped: true
    }, {
        enemy: bundles.enemy18.jsons.enemy18,
        x: -45,
        y: -20
    }],

    "88": [{
        enemy: bundles.enemy18.jsons.enemy18,
        x: -205,
        y: -95,
        flipped: true
    }, {
        enemy: bundles.enemy19.jsons.enemy19,
        x: 135,
        y: -65
    }, {
        enemy: bundles.enemy17.jsons.enemy17,
        x: 15,
        y: -105
    }],

    "89": [{
        enemy: bundles.enemy14.jsons.enemy14,
        x: -10,
        y: -135,
        flipped: true
    }],

    "90": [{
        enemy: bundles.enemy18.jsons.enemy18,
        x: 160,
        y: -205,
        flipped: true
    }, {
        enemy: bundles.enemy9.jsons.enemy9,
        x: -80,
        y: -135
    }],

    "91": [{
        enemy: bundles.enemy0.jsons.enemy0,
        x: 240,
        y: -130,
        flipped: true
    }, {
        enemy: bundles.enemy17.jsons.enemy17,
        x: 0,
        y: -65
    }],

    "92": [{
        enemy: bundles.enemy6.jsons.enemy6,
        x: -200,
        y: -100,
        flipped: true
    }, {
        enemy: bundles.enemy14.jsons.enemy14,
        x: 20,
        y: 20
    }, {
        enemy: bundles.enemy19.jsons.enemy19,
        x: 70,
        y: -65
    }],

    "93": [{
        enemy: bundles.enemy0.jsons.enemy0,
        x: -200,
        y: -60,
        flipped: true
    }, {
        enemy: bundles.enemy18.jsons.enemy18,
        x: 10,
        y: 0
    }, {
        enemy: bundles.enemy3.jsons.enemy3,
        x: 180,
        y: -120
    }],

    "94": [{
        enemy: bundles.enemy5.jsons.enemy5,
        x: -140,
        y: -110,
        flipped: true
    }],

    "95": [{
        enemy: bundles.enemy17.jsons.enemy17,
        x: -10,
        y: -40,
        flipped: true
    }],

    "96": [{
        enemy: bundles.enemy5.jsons.enemy5,
        x: 110,
        y: -120,
        flipped: true
    }, {
        enemy: bundles.enemy14.jsons.enemy14,
        x: -130,
        y: -50,
        flipped: true
    }],

    "97": [{
        enemy: bundles.enemy16.jsons.enemy16,
        x: -30,
        y: -200,
        flipped: true
    }, {
        enemy: bundles.enemy11.jsons.enemy11,
        x: -90,
        y: -20,
        flipped: true
    }],

    "98": [{
        enemy: bundles.enemy4.jsons.enemy4,
        x: -50,
        y: -30,
        flipped: true
    }],

    "99": [{
        enemy: bundles.enemy3.jsons.enemy3,
        x: -80,
        y: -240,
        flipped: true
    }, {
        enemy: bundles.enemy14.jsons.enemy14,
        x: -30,
        y: -10,
        flipped: true
    }],

    "100": [{
        enemy: bundles.enemy1.jsons.enemy1,
        x: 180,
        y: -140,
        flipped: true
    }, {
        enemy: bundles.enemy2.jsons.enemy2,
        x: -80,
        y: -120
    }],

    "101": [{
        enemy: bundles.enemy19.jsons.enemy19,
        x: 180,
        y: -140,
        flipped: true
    }],

    "102": [{
        enemy: bundles.enemy6.jsons.enemy6,
        x: 130,
        y: -100,
        flipped: true
    }, {
        enemy: bundles.enemy17.jsons.enemy17,
        x: -120,
        y: -60
    }],

    "103": [{
        enemy: bundles.enemy1.jsons.enemy1,
        x: 150,
        y: -100,
        flipped: true
    }, {
        enemy: bundles.enemy2.jsons.enemy2,
        x: -120,
        y: -60
    }],

    "104": [{
        enemy: bundles.enemy14.jsons.enemy14,
        x: 20,
        y: 70,
        flipped: true
    }],

    "105": [{
        enemy: bundles.enemy13.jsons.enemy13,
        x: 160,
        y: -40,
        flipped: true
    }, {
        enemy: bundles.enemy16.jsons.enemy16,
        x: -20,
        y: -130
    }],

    "106": [{
        enemy: bundles.enemy3.jsons.enemy3,
        x: 70,
        y: -40,
        flipped: true
    }],

    "107": [{
        enemy: bundles.enemy12.jsons.enemy12,
        x: 50,
        y: -10,
        flipped: true
    }, {
        enemy: bundles.enemy14.jsons.enemy14,
        x: -90,
        y: 30
    }],

    "108": [{
        enemy: bundles.enemy5.jsons.enemy5,
        x: -120,
        y: -20,
        flipped: true
    }],

    "109": [{
        enemy: bundles.enemy18.jsons.enemy18,
        x: 80,
        y: 40,
        flipped: true
    }],

    "110": [{
        enemy: bundles.enemy11.jsons.enemy11,
        x: 20,
        y: 60,
        flipped: true
    }, {
        enemy: bundles.enemy16.jsons.enemy16,
        x: 110,
        y: -140
    }],

    "111": [{
        enemy: bundles.enemy11.jsons.enemy11,
        x: 140,
        y: 0,
        flipped: true
    }, {
        enemy: bundles.enemy15.jsons.enemy15,
        x: -120,
        y: -70
    }],

    "112": [{
        enemy: bundles.enemy0.jsons.enemy0,
        x: 110,
        y: -80,
        flipped: true
    }, {
        enemy: bundles.enemy7.jsons.enemy7,
        x: -120,
        y: -50
    }],

    "113": [{
        enemy: bundles.enemy3.jsons.enemy3,
        x: 190,
        y: 10,
        flipped: true
    }, {
        enemy: bundles.enemy13.jsons.enemy13,
        x: -100,
        y: -90
    }, {
        enemy: bundles.enemy14.jsons.enemy14,
        x: -70,
        y: 20
    }],

    "114": [{
        enemy: bundles.enemy2.jsons.enemy2,
        x: 150,
        y: -180,
        flipped: true
    }, {
        enemy: bundles.enemy6.jsons.enemy6,
        x: -170,
        y: -160
    }, {
        enemy: bundles.enemy9.jsons.enemy9,
        x: -150,
        y: -20,
        flipped: true
    }],

    "115": [{
        enemy: bundles.enemy13.jsons.enemy13,
        x: -20,
        y: -100
    }, {
        enemy: bundles.enemy17.jsons.enemy17,
        x: 110,
        y: 20,
        flipped: true
    }, {
        enemy: bundles.enemy18.jsons.enemy18,
        x: -220,
        y: -100,
        flipped: true
    }],

    "116": [{
        enemy: bundles.enemy0.jsons.enemy0,
        x: 70,
        y: -180
    }, {
        enemy: bundles.enemy1.jsons.enemy1,
        x: 130,
        y: 30,
        flipped: true
    }, {
        enemy: bundles.enemy19.jsons.enemy19,
        x: -180,
        y: -100,
        flipped: true
    }],

    "117": [{
        enemy: bundles.enemy2.jsons.enemy2,
        x: 240,
        y: -120,
        flipped: true
    }, {
        enemy: bundles.enemy5.jsons.enemy5,
        x: -170,
        y: -40,
        flipped: true
    }, {
        enemy: bundles.enemy16.jsons.enemy16,
        x: 0,
        y: -120,
        flipped: true
    }],

    "118": [{
        enemy: bundles.enemy7.jsons.enemy7,
        x: 100,
        y: 30
    }, {
        enemy: bundles.enemy19.jsons.enemy19,
        x: -200,
        y: -110,
        flipped: true
    }],

    "119": [{
        enemy: bundles.enemy8.jsons.enemy8,
        x: 100,
        y: -160
    }, {
        enemy: bundles.enemy18.jsons.enemy18,
        x: -160,
        y: -40,
        flipped: true
    }, {
        enemy: bundles.enemy19.jsons.enemy19,
        x: -140,
        y: -200,
        flipped: true
    }],

    "120": [{
        enemy: bundles.enemy2.jsons.enemy2,
        x: 20,
        y: -120
    }, {
        enemy: bundles.enemy8.jsons.enemy8,
        x: 60,
        y: 20,
        flipped: true
    }, {
        enemy: bundles.enemy19.jsons.enemy19,
        x: -100,
        y: -200,
        flipped: true
    }],

    "121": [{
        enemy: bundles.enemy2.jsons.enemy2,
        x: 100,
        y: -160
    }, {
        enemy: bundles.enemy16.jsons.enemy16,
        x: -140,
        y: -100,
        flipped: true
    }],

    "122": [{
        enemy: bundles.enemy7.jsons.enemy7,
        x: 20,
        y: -180
    }, {
        enemy: bundles.enemy14.jsons.enemy14,
        x: -30,
        y: -50,
        flipped: true
    }],

    "123": [{
        enemy: bundles.enemy0.jsons.enemy0,
        x: 110,
        y: -160
    }, {
        enemy: bundles.enemy6.jsons.enemy6,
        x: 160,
        y: -10,
        flipped: true
    }, {
        enemy: bundles.enemy8.jsons.enemy8,
        x: -100,
        y: -120,
        flipped: true
    }],

    "124": [{
        enemy: bundles.enemy3.jsons.enemy3,
        x: 40,
        y: -120
    }, {
        enemy: bundles.enemy19.jsons.enemy19,
        x: -150,
        y: -50,
        flipped: true
    }],

    "125": [{
        enemy: bundles.enemy2.jsons.enemy2,
        x: 80,
        y: -140
    }, {
        enemy: bundles.enemy7.jsons.enemy7,
        x: 60,
        y: 20
    }, {
        enemy: bundles.enemy17.jsons.enemy17,
        x: -100,
        y: -120,
        flipped: true
    }],

    "126": [{
        enemy: bundles.enemy4.jsons.enemy4,
        x: -220,
        y: -120,
        flipped: true
    }, {
        enemy: bundles.enemy5.jsons.enemy5,
        x: -60,
        y: -130
    }, {
        enemy: bundles.enemy6.jsons.enemy6,
        x: 230,
        y: -60,
        flipped: true
    }],

    "127": [{
        enemy: bundles.enemy7.jsons.enemy7,
        x: -220,
        y: -40,
        flipped: true
    }, {
        enemy: bundles.enemy17.jsons.enemy17,
        x: 0,
        y: -200
    }, {
        enemy: bundles.enemy11.jsons.enemy11,
        x: 0,
        y: 90,
        flipped: true
    }],

    "128": [{
        enemy: bundles.enemy10.jsons.enemy10,
        x: -160,
        y: -200,
        flipped: true
    }, {
        enemy: bundles.enemy14.jsons.enemy14,
        x: 40,
        y: -150
    }],

    "129": [{
        enemy: bundles.enemy4.jsons.enemy4,
        x: -140,
        y: -50,
        flipped: true
    }, {
        enemy: bundles.enemy7.jsons.enemy7,
        x: 20,
        y: -160
    }],

    "130": [{
        enemy: bundles.enemy3.jsons.enemy3,
        x: 0,
        y: -160,
        flipped: true
    }, {
        enemy: bundles.enemy9.jsons.enemy9,
        x: 120,
        y: 0
    }],

    "131": [{
        enemy: bundles.enemy5.jsons.enemy5,
        x: -190,
        y: -130,
        flipped: true
    }, {
        enemy: bundles.enemy8.jsons.enemy8,
        x: 50,
        y: -140
    }, {
        enemy: bundles.enemy17.jsons.enemy17,
        x: 280,
        y: -60,
        flipped: true
    }],

    "132": [
        {
            enemy: bundles.enemy7.jsons.enemy7,
            x: -220,
            y: -80,
            flipped: true
        },
        {
            enemy: bundles.enemy14.jsons.enemy14,
            x: 10,
            y: -60
        }
    ],

    "133": [
        {
            enemy: bundles.enemy9.jsons.enemy9,
            x: -220,
            y: -110
        },
        {
            enemy: bundles.enemy6.jsons.enemy6,
            x: 10,
            y: -130
        }
    ],

    "134": [
        {
            enemy: bundles.enemy4.jsons.enemy4,
            x: -130,
            y: -50
        },
        {
            enemy: bundles.enemy15.jsons.enemy15,
            x: 110,
            y: -90
        }
    ],

    "135": [
        {
            enemy: bundles.enemy11.jsons.enemy11,
            x: -60,
            y: -20
        },
        {
            enemy: bundles.enemy8.jsons.enemy8,
            x: 110,
            y: -90,
            flipped: true
        }
    ],

    "136": [
        {
            enemy: bundles.enemy2.jsons.enemy2,
            x: -80,
            y: -90
        },
        {
            enemy: bundles.enemy7.jsons.enemy7,
            x: 130,
            y: -90,
            flipped: true
        }
    ],

    "137": [
        {
            enemy: bundles.enemy5.jsons.enemy5,
            x: -120,
            y: -90
        },
        {
            enemy: bundles.enemy9.jsons.enemy9,
            x: 80,
            y: -130,
            flipped: true
        }
    ],

    "138": [
        {
            enemy: bundles.enemy10.jsons.enemy10,
            x: -120,
            y: -90
        },
        {
            enemy: bundles.enemy1.jsons.enemy1,
            x: 110,
            y: -40,
            flipped: true
        }
    ],

    "139": [
        {
            enemy: bundles.enemy3.jsons.enemy3,
            x: -210,
            y: 0,
            flipped: true
        },
        {
            enemy: bundles.enemy13.jsons.enemy13,
            x: 80,
            y: -100
        }
    ],

    "140": [
        {
            enemy: bundles.enemy4.jsons.enemy4,
            x: -100,
            y: -100
        },
        {
            enemy: bundles.enemy15.jsons.enemy15,
            x: 80,
            y: -130,
            flipped: true
        }
    ],

    "141": [
        {
            enemy: bundles.enemy8.jsons.enemy8,
            x: -70,
            y: -60,
            flipped: true
        },
        {
            enemy: bundles.enemy16.jsons.enemy16,
            x: 110,
            y: -130
        }
    ],

    "142": [
        {
            enemy: bundles.enemy3.jsons.enemy3,
            x: -100,
            y: -80,
            flipped: true
        },
        {
            enemy: bundles.enemy9.jsons.enemy9,
            x: 80,
            y: -100
        }
    ],

    "143": [
        {
            enemy: bundles.enemy2.jsons.enemy2,
            x: -150,
            y: -80,
            flipped: true
        },
        {
            enemy: bundles.enemy15.jsons.enemy15,
            x: 20,
            y: -140
        }
    ],

    "144": [
        {
            enemy: bundles.enemy6.jsons.enemy6,
            x: -90,
            y: -60,
            flipped: true
        },
        {
            enemy: bundles.enemy17.jsons.enemy17,
            x: 40,
            y: -90
        }
    ],

    "145": [
        {
            enemy: bundles.enemy3.jsons.enemy3,
            x: -70,
            y: -130
        },
        {
            enemy: bundles.enemy13.jsons.enemy13,
            x: 60,
            y: -60,
            flipped: true
        }
    ],

    "146": [
        {
            enemy: bundles.enemy4.jsons.enemy4,
            x: -70,
            y: 10,
            flipped: true
        },
        {
            enemy: bundles.enemy12.jsons.enemy12,
            x: 110,
            y: 50
        }
    ],

    "147": [
        {
            enemy: bundles.enemy6.jsons.enemy6,
            x: -70,
            y: -60,
            flipped: true
        },
        {
            enemy: bundles.enemy1.jsons.enemy1,
            x: 100,
            y: -120
        },
        {
            enemy: bundles.enemy14.jsons.enemy14,
            x: 270,
            y: 0
        }
    ],

    "148": [
        {
            enemy: bundles.enemy7.jsons.enemy7,
            x: -90,
            y: -60
        },
        {
            enemy: bundles.enemy5.jsons.enemy5,
            x: 80,
            y: -160,
            flipped: true
        }
    ],

    "149": [
        {
            enemy: bundles.enemy4.jsons.enemy4,
            x: -130,
            y: -30
        },
        {
            enemy: bundles.enemy8.jsons.enemy8,
            x: 70,
            y: -70,
            flipped: true
        }
    ],

    "150": [
        {
            enemy: bundles.enemy9.jsons.enemy9,
            x: -70,
            y: -40,
            flipped: true
        },
        {
            enemy: bundles.enemy10.jsons.enemy10,
            x: 50,
            y: -150
        }
    ],

    "151": [
        {
            enemy: bundles.enemy11.jsons.enemy11,
            x: -70,
            y: -130,
            flipped: true
        },
        {
            enemy: bundles.enemy13.jsons.enemy13,
            x: 110,
            y: -130
        }
    ],

    "152": [
        {
            enemy: bundles.enemy8.jsons.enemy8,
            x: 20,
            y: -140
        },
        {
            enemy: bundles.enemy15.jsons.enemy15,
            x: 160,
            y: 20,
            flipped: true
        }
    ],

    "153": [
        {
            enemy: bundles.enemy9.jsons.enemy9,
            x: -80,
            y: -140
        },
        {
            enemy: bundles.enemy8.jsons.enemy8,
            x: 160,
            y: -100,
            flipped: true
        }
    ],

    "154": [
        {
            enemy: bundles.enemy3.jsons.enemy3,
            x: -45,
            y: -58
        },
        {
            enemy: bundles.enemy10.jsons.enemy10,
            x: 154,
            y: -31,
            flipped: true
        }
    ],

    "155": [
        {
            enemy: bundles.enemy15.jsons.enemy15,
            x: -40,
            y: -6
        },
        {
            enemy: bundles.enemy16.jsons.enemy16,
            x: 111,
            y: -89,
            flipped: true
        }
    ],

    "156": [
        {
            enemy: bundles.enemy2.jsons.enemy2,
            x: -89,
            y: -4
        },
        {
            enemy: bundles.enemy4.jsons.enemy4,
            x: 87,
            y: -10,
            flipped: true
        }
    ],

    "157": [
        {
            enemy: bundles.enemy14.jsons.enemy14,
            x: -118,
            y: -5
        },
        {
            enemy: bundles.enemy13.jsons.enemy13,
            x: 59,
            y: -43,
            flipped: true
        }
    ],

    "158": [
        {
            enemy: bundles.enemy1.jsons.enemy1,
            x: -53,
            y: -88
        },
        {
            enemy: bundles.enemy0.jsons.enemy0,
            x: 17,
            y: 160,
            flipped: true
        }
    ],

    "159": [
        {
            enemy: bundles.enemy10.jsons.enemy10,
            x: -93,
            y: -18
        },
        {
            enemy: bundles.enemy14.jsons.enemy14,
            x: 97,
            y: -60,
            flipped: true
        }
    ],

    "160": [
        {
            enemy: bundles.enemy15.jsons.enemy15,
            x: -157,
            y: -60
        },
        {
            enemy: bundles.enemy14.jsons.enemy14,
            x: 197,
            y: -80,
            flipped: true
        }
    ],

    "161": [
        {
            enemy: bundles.enemy2.jsons.enemy2,
            x: -193,
            y: -168
        },
        {
            enemy: bundles.enemy4.jsons.enemy4,
            x: 117,
            y: -160,
            flipped: true
        }
    ],

    "162": [
        {
            enemy: bundles.enemy1.jsons.enemy1,
            x: -153,
            y: -68
        },
        {
            enemy: bundles.enemy0.jsons.enemy0,
            x: 77,
            y: -90,
            flipped: true
        }
    ],

    "163": [
        {
            enemy: bundles.enemy11.jsons.enemy11,
            x: -166,
            y: -34
        },
        {
            enemy: bundles.enemy10.jsons.enemy10,
            x: 17,
            y: -88,
            flipped: true
        }
    ],

    "164": [
        {
            enemy: bundles.enemy14.jsons.enemy14,
            x: -165,
            y: -69
        },
        {
            enemy: bundles.enemy2.jsons.enemy2,
            x: -1,
            y: 20,
            flipped: true
        }
    ],

    "165": [
        {
            enemy: bundles.enemy4.jsons.enemy4,
            x: -126,
            y: -15
        },
        {
            enemy: bundles.enemy3.jsons.enemy3,
            x: 133,
            y: -54,
            flipped: true
        }
    ],

    "166": [
        {
            enemy: bundles.enemy5.jsons.enemy5,
            x: -67,
            y: -157
        },
        {
            enemy: bundles.enemy10.jsons.enemy10,
            x: 22,
            y: -38,
            flipped: true
        }
    ],

    "167": [
        {
            enemy: bundles.enemy1.jsons.enemy1,
            x: 165,
            y: -41,
            flipped: true
        }
    ],

    "168": [
        {
            enemy: bundles.enemy15.jsons.enemy15,
            x: -183,
            y: -50
        },
        {
            enemy: bundles.enemy16.jsons.enemy16,
            x: 116,
            y: -38,
            flipped: true
        }
    ],

    enemies: {
        scale: 0.65,
        idleAnimation: "idle",
        attackAnimation: "attack",
        dieAnimation: "die",
        x: 200,
        y: 150,
        delta: {
            x: -40,
            y: 0
        }
    },

    friends: {
        scale: 0.8,
        idleAnimation: "idle",
        attackAnimation: "out",
        x: 200,
        y: 184,
        delta: {
            x: 40,
            y: 0
        }
    }
};
