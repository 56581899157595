/**
 * Created by decipaliz on 14.03.2024
 */

GoalsView.prototype.createStartAnimation = function () {
    var scene = cleverapps.scenes.getRunningScene();
    var styles = cleverapps.styles.GoalsView.startAnimation;

    this.views.forEach(function (goalView, id) {
        goalView.setVisible(true);

        goalView.life.baseScale = goalView.life.getScale();
        goalView.life.setVisible(false);
        goalView.life.setScale(0);

        goalView.basePosition = goalView.getPosition();

        var goalsTargetPosition = goalView.getParent().convertToNodeSpace(cc.p(
            scene.width / 2 + styles.goalsPositions[this.views.length - 1][id].x,
            scene.height / 2 + styles.goalsPositions[this.views.length - 1][id].y
        ));
        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            goalsTargetPosition.x += styles.mobileOffsetX;
        }

        goalView.setPosition(goalsTargetPosition);
        goalView.runAction(
            new cc.Sequence(
                new cc.DelayTime(Math.random() * 0.5),
                new cc.CallFunc(function () {
                    goalView.actor.animate(Actor.ANIMATIONS.ENEMY_ATTACK);
                }),
                new cc.DelayTime(1 + Math.random() * 0.5),
                new cc.CallFunc(function () {
                    goalView.actor.animate(Actor.ANIMATIONS.ENEMY_ATTACK);
                })
            )
        );
    }.bind(this));
};

GoalsView.prototype.createCarAnimation = function () {};

GoalsView.prototype.showUpAnimationFinish = function () {
    this.views.forEach(function (view) {
        view.stopAllActions();
        view.runAction(new cc.MoveTo(0.7, view.basePosition)).setFinalize(function () {
            view.setPosition(view.basePosition);

            view.life.stopAllActions();
            view.life.setVisible(true);
            view.life.runAction(new cc.ScaleTo(0.3, view.life.baseScale));
        });
    });
};