/**
 * Created by decipaliz on 29.03.2024
 */

Actor.prototype.showUpAnimation = function (silent) {
    if (silent) {
        this.setVisible(true);
        this.setupChildren();
        return;
    }

    var styles = cleverapps.styles.Actor;
    var scene = cleverapps.scenes.getRunningScene();

    this.basePosition = this.getPosition();
    var playerTargetPosition = this.getParent().convertToNodeSpace(cc.p(
        scene.width / 2 + styles.animation.playerPositions.x,
        scene.height / 2 + styles.animation.playerPositions.y
    ));
    if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
        playerTargetPosition.x -= styles.animation.mobileOffsetX;
    }

    this.setVisible(true);
    this.setPositionRound(playerTargetPosition.x + this.width, playerTargetPosition.y);

    cleverapps.focusManager.showControlsWhileFocused("panel_controls");

    this.showUpAction = new cc.Spawn(
        new cc.MoveTo(0.4, playerTargetPosition).easing(cc.easeElasticOut(2.0)),
        new cc.Sequence(
            new cc.DelayTime(0.9),
            new cc.CallFunc(function () {
                this.animate(Actor.ANIMATIONS.HURT);
            }.bind(this)),
            new cc.DelayTime(1.2),
            new cc.CallFunc(function () {
                this.animate(Actor.ANIMATIONS.HURT);
            }.bind(this)),
            new cc.DelayTime(0.4)
        )
    ).setFinalize(function () {
        this.setPositionRound(playerTargetPosition);
        delete this.showUpAction;
    }.bind(this));

    this.runAction(this.showUpAction);
};